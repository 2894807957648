/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Authentication API
 * Authentication API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: ezverinskaia@integria-insurance.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as urlUtils from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://api.integria-systems.com/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AccountDetails
 */
export interface AccountDetails {
    /**
     * 
     * @type {string}
     * @memberof AccountDetails
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetails
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetails
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetails
     */
    phoneNumber?: string;
}

/**
 * 
 * @export
 * @interface AuthenticationResponse
 */
export interface AuthenticationResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    access_token?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    token_type?: string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationResponse
     */
    expires_in?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    refresh_token?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationResponse
     */
    authenticated?: boolean;
    /**
     * 
     * @type {UserInfo}
     * @memberof AuthenticationResponse
     */
    user?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    twoFactorAuthQrCode?: string;
}

/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    password?: string;
}

/**
 * 
 * @export
 * @interface PasswordResetInstructionsRequest
 */
export interface PasswordResetInstructionsRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetInstructionsRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetInstructionsRequest
     */
    locale?: PasswordResetInstructionsRequest_LocaleEnum;
}
/**
 * @export
 * @enum {string}
 */
export enum PasswordResetInstructionsRequest_LocaleEnum {
    De = 'de' as any,
    En = 'en' as any
}

/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    resetPasswordToken?: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    newPassword?: string;
}

/**
 * 
 * @export
 * @interface SignupRequest
 */
export interface SignupRequest {
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SignupRequest
     */
    enableTwoFactorAuthentication?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignupRequest
     */
    registrationCode?: string;
}

/**
 * 
 * @export
 * @interface UpdatePasswordRequest
 */
export interface UpdatePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordRequest
     */
    currentPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordRequest
     */
    newPassword?: string;
}

/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    id?: string;
    /**
     * 
     * @type {AccountDetails}
     * @memberof UserInfo
     */
    accountDetails?: AccountDetails;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    role?: string;
}

/**
 * 
 * @export
 * @interface VerificationRequest
 */
export interface VerificationRequest {
    /**
     * 
     * @type {string}
     * @memberof VerificationRequest
     */
    code?: string;
}


/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deactivate user
         * @summary Deactivate user
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUser(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deactivateUser.');
            }
            const localVarPath = `/auth/deactivateUser/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Refresh access tocken using refresh token
         * @summary Refresh access tocken using refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(options: any = {}): FetchArgs {
            const localVarPath = `/auth/refresh`;
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request password reset instructions
         * @summary Request password reset instructions
         * @param {PasswordResetInstructionsRequest} passwordResetInstructionsRequest Password request instructions request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordResetInstructions(passwordResetInstructionsRequest: PasswordResetInstructionsRequest, options: any = {}): FetchArgs {
            // verify required parameter 'passwordResetInstructionsRequest' is not null or undefined
            if (passwordResetInstructionsRequest === null || passwordResetInstructionsRequest === undefined) {
                throw new RequiredError('passwordResetInstructionsRequest','Required parameter passwordResetInstructionsRequest was null or undefined when calling requestPasswordResetInstructions.');
            }
            const localVarPath = `/auth/requestPasswordResetInstructions`;
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);
            const needsSerialization = ("PasswordResetInstructionsRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(passwordResetInstructionsRequest || {}) : (passwordResetInstructionsRequest || "");

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset password
         * @summary Reset password
         * @param {ResetPasswordRequest} resetPasswordRequest Reset password request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordRequest: ResetPasswordRequest, options: any = {}): FetchArgs {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            if (resetPasswordRequest === null || resetPasswordRequest === undefined) {
                throw new RequiredError('resetPasswordRequest','Required parameter resetPasswordRequest was null or undefined when calling resetPassword.');
            }
            const localVarPath = `/auth/resetPassword`;
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);
            const needsSerialization = ("ResetPasswordRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(resetPasswordRequest || {}) : (resetPasswordRequest || "");

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sign in with login request
         * @summary Sign in with login request
         * @param {LoginRequest} loginRequest Login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signin(loginRequest: LoginRequest, options: any = {}): FetchArgs {
            // verify required parameter 'loginRequest' is not null or undefined
            if (loginRequest === null || loginRequest === undefined) {
                throw new RequiredError('loginRequest','Required parameter loginRequest was null or undefined when calling signin.');
            }
            const localVarPath = `/auth/signin`;
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);
            const needsSerialization = ("LoginRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(loginRequest || {}) : (loginRequest || "");

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sign up with signup request
         * @summary Sign up with signup request
         * @param {SignupRequest} signupRequest Signup request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(signupRequest: SignupRequest, options: any = {}): FetchArgs {
            // verify required parameter 'signupRequest' is not null or undefined
            if (signupRequest === null || signupRequest === undefined) {
                throw new RequiredError('signupRequest','Required parameter signupRequest was null or undefined when calling signup.');
            }
            const localVarPath = `/auth/signup`;
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);
            const needsSerialization = ("SignupRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(signupRequest || {}) : (signupRequest || "");

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update account details
         * @summary Update account details
         * @param {AccountDetails} accountDetails Account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDetails(accountDetails: AccountDetails, options: any = {}): FetchArgs {
            // verify required parameter 'accountDetails' is not null or undefined
            if (accountDetails === null || accountDetails === undefined) {
                throw new RequiredError('accountDetails','Required parameter accountDetails was null or undefined when calling updateAccountDetails.');
            }
            const localVarPath = `/auth/updateAccountDetails`;
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);
            const needsSerialization = ("AccountDetails" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(accountDetails || {}) : (accountDetails || "");

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update password
         * @summary Update password
         * @param {UpdatePasswordRequest} updatePasswordRequest Update password request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(updatePasswordRequest: UpdatePasswordRequest, options: any = {}): FetchArgs {
            // verify required parameter 'updatePasswordRequest' is not null or undefined
            if (updatePasswordRequest === null || updatePasswordRequest === undefined) {
                throw new RequiredError('updatePasswordRequest','Required parameter updatePasswordRequest was null or undefined when calling updatePassword.');
            }
            const localVarPath = `/auth/updatePassword`;
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);
            const needsSerialization = ("UpdatePasswordRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updatePasswordRequest || {}) : (updatePasswordRequest || "");

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify second factor authentication code
         * @summary Verify second factor authentication code
         * @param {VerificationRequest} verificationRequest Verification request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify(verificationRequest: VerificationRequest, options: any = {}): FetchArgs {
            // verify required parameter 'verificationRequest' is not null or undefined
            if (verificationRequest === null || verificationRequest === undefined) {
                throw new RequiredError('verificationRequest','Required parameter verificationRequest was null or undefined when calling verify.');
            }
            const localVarPath = `/auth/verify`;
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VerificationRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(verificationRequest || {}) : (verificationRequest || "");

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Deactivate user
         * @summary Deactivate user
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUser(userId: string, options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).deactivateUser(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.deactivateUser(userId, options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Refresh access tocken using refresh token
         * @summary Refresh access tocken using refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<AuthenticationResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).refresh(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return new Promise((resolve, reject) =>
                            response.text()
                                .then((text) => resolve(text ? JSON.parse(text) : null))
                                .catch((reason) => reject(reason)));
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.refresh(options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Request password reset instructions
         * @summary Request password reset instructions
         * @param {PasswordResetInstructionsRequest} passwordResetInstructionsRequest Password request instructions request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordResetInstructions(passwordResetInstructionsRequest: PasswordResetInstructionsRequest, options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).requestPasswordResetInstructions(passwordResetInstructionsRequest, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.requestPasswordResetInstructions(passwordResetInstructionsRequest, options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Reset password
         * @summary Reset password
         * @param {ResetPasswordRequest} resetPasswordRequest Reset password request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).resetPassword(resetPasswordRequest, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.resetPassword(resetPasswordRequest, options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sign in with login request
         * @summary Sign in with login request
         * @param {LoginRequest} loginRequest Login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signin(loginRequest: LoginRequest, options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<AuthenticationResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).signin(loginRequest, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return new Promise((resolve, reject) =>
                            response.text()
                                .then((text) => resolve(text ? JSON.parse(text) : null))
                                .catch((reason) => reject(reason)));
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.signin(loginRequest, options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Sign up with signup request
         * @summary Sign up with signup request
         * @param {SignupRequest} signupRequest Signup request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(signupRequest: SignupRequest, options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<AuthenticationResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).signup(signupRequest, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return new Promise((resolve, reject) =>
                            response.text()
                                .then((text) => resolve(text ? JSON.parse(text) : null))
                                .catch((reason) => reject(reason)));
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.signup(signupRequest, options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update account details
         * @summary Update account details
         * @param {AccountDetails} accountDetails Account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDetails(accountDetails: AccountDetails, options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).updateAccountDetails(accountDetails, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.updateAccountDetails(accountDetails, options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Update password
         * @summary Update password
         * @param {UpdatePasswordRequest} updatePasswordRequest Update password request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(updatePasswordRequest: UpdatePasswordRequest, options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).updatePassword(updatePasswordRequest, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.updatePassword(updatePasswordRequest, options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Verify second factor authentication code
         * @summary Verify second factor authentication code
         * @param {VerificationRequest} verificationRequest Verification request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify(verificationRequest: VerificationRequest, options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<AuthenticationResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).verify(verificationRequest, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return new Promise((resolve, reject) =>
                            response.text()
                                .then((text) => resolve(text ? JSON.parse(text) : null))
                                .catch((reason) => reject(reason)));
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.verify(verificationRequest, options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Deactivate user
         * @summary Deactivate user
         * @param {string} userId User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUser(userId: string, options?: any) {
            return AuthApiFp(configuration).deactivateUser(userId, options)(fetch, basePath);
        },
        /**
         * Refresh access tocken using refresh token
         * @summary Refresh access tocken using refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(options?: any) {
            return AuthApiFp(configuration).refresh(options)(fetch, basePath);
        },
        /**
         * Request password reset instructions
         * @summary Request password reset instructions
         * @param {PasswordResetInstructionsRequest} passwordResetInstructionsRequest Password request instructions request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestPasswordResetInstructions(passwordResetInstructionsRequest: PasswordResetInstructionsRequest, options?: any) {
            return AuthApiFp(configuration).requestPasswordResetInstructions(passwordResetInstructionsRequest, options)(fetch, basePath);
        },
        /**
         * Reset password
         * @summary Reset password
         * @param {ResetPasswordRequest} resetPasswordRequest Reset password request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: any) {
            return AuthApiFp(configuration).resetPassword(resetPasswordRequest, options)(fetch, basePath);
        },
        /**
         * Sign in with login request
         * @summary Sign in with login request
         * @param {LoginRequest} loginRequest Login request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signin(loginRequest: LoginRequest, options?: any) {
            return AuthApiFp(configuration).signin(loginRequest, options)(fetch, basePath);
        },
        /**
         * Sign up with signup request
         * @summary Sign up with signup request
         * @param {SignupRequest} signupRequest Signup request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(signupRequest: SignupRequest, options?: any) {
            return AuthApiFp(configuration).signup(signupRequest, options)(fetch, basePath);
        },
        /**
         * Update account details
         * @summary Update account details
         * @param {AccountDetails} accountDetails Account details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountDetails(accountDetails: AccountDetails, options?: any) {
            return AuthApiFp(configuration).updateAccountDetails(accountDetails, options)(fetch, basePath);
        },
        /**
         * Update password
         * @summary Update password
         * @param {UpdatePasswordRequest} updatePasswordRequest Update password request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(updatePasswordRequest: UpdatePasswordRequest, options?: any) {
            return AuthApiFp(configuration).updatePassword(updatePasswordRequest, options)(fetch, basePath);
        },
        /**
         * Verify second factor authentication code
         * @summary Verify second factor authentication code
         * @param {VerificationRequest} verificationRequest Verification request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verify(verificationRequest: VerificationRequest, options?: any) {
            return AuthApiFp(configuration).verify(verificationRequest, options)(fetch, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Deactivate user
     * @summary Deactivate user
     * @param {string} userId User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public deactivateUser(userId: string, options?: any) {
        return AuthApiFp(this.configuration).deactivateUser(userId, options)(this.fetch, this.basePath);
    }

    /**
     * Refresh access tocken using refresh token
     * @summary Refresh access tocken using refresh token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refresh(options?: any) {
        return AuthApiFp(this.configuration).refresh(options)(this.fetch, this.basePath);
    }

    /**
     * Request password reset instructions
     * @summary Request password reset instructions
     * @param {PasswordResetInstructionsRequest} passwordResetInstructionsRequest Password request instructions request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public requestPasswordResetInstructions(passwordResetInstructionsRequest: PasswordResetInstructionsRequest, options?: any) {
        return AuthApiFp(this.configuration).requestPasswordResetInstructions(passwordResetInstructionsRequest, options)(this.fetch, this.basePath);
    }

    /**
     * Reset password
     * @summary Reset password
     * @param {ResetPasswordRequest} resetPasswordRequest Reset password request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: any) {
        return AuthApiFp(this.configuration).resetPassword(resetPasswordRequest, options)(this.fetch, this.basePath);
    }

    /**
     * Sign in with login request
     * @summary Sign in with login request
     * @param {LoginRequest} loginRequest Login request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signin(loginRequest: LoginRequest, options?: any) {
        return AuthApiFp(this.configuration).signin(loginRequest, options)(this.fetch, this.basePath);
    }

    /**
     * Sign up with signup request
     * @summary Sign up with signup request
     * @param {SignupRequest} signupRequest Signup request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public signup(signupRequest: SignupRequest, options?: any) {
        return AuthApiFp(this.configuration).signup(signupRequest, options)(this.fetch, this.basePath);
    }

    /**
     * Update account details
     * @summary Update account details
     * @param {AccountDetails} accountDetails Account details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public updateAccountDetails(accountDetails: AccountDetails, options?: any) {
        return AuthApiFp(this.configuration).updateAccountDetails(accountDetails, options)(this.fetch, this.basePath);
    }

    /**
     * Update password
     * @summary Update password
     * @param {UpdatePasswordRequest} updatePasswordRequest Update password request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public updatePassword(updatePasswordRequest: UpdatePasswordRequest, options?: any) {
        return AuthApiFp(this.configuration).updatePassword(updatePasswordRequest, options)(this.fetch, this.basePath);
    }

    /**
     * Verify second factor authentication code
     * @summary Verify second factor authentication code
     * @param {VerificationRequest} verificationRequest Verification request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public verify(verificationRequest: VerificationRequest, options?: any) {
        return AuthApiFp(this.configuration).verify(verificationRequest, options)(this.fetch, this.basePath);
    }

}

