import {AuthApi, AuthApiFp, Configuration as AuthApiConfiguration} from "@src/api/typescript-auth-api-client"
import {DataApi, Configuration as DataApiConfiguration} from "@src/api/typescript-data-api-client"
import {AdminApi, Configuration as AdminApiConfiguration} from "@src/api/typescript-admin-api-client"
import JwtService from "./jwtService"

export const jwtService = new JwtService()

const refreshAccessToken = () => {
    const refreshAccessTokenResult = new Promise(resolve => {
        jwtService.addSubscriber(accessToken => {
            resolve(accessToken)
        })
    })
    if (!jwtService.isAlreadyFetchingAccessToken) {
        jwtService.isAlreadyFetchingAccessToken = true
        const refreshTokenApiConfiguration = new AuthApiConfiguration({
            basePath: process.env.REACT_APP_API_BASE_URL,
            accessToken: () => jwtService.getRefreshToken(),
            defaultHeaders: {'Access-Control-Allow-Origin': '*'}
        })
        AuthApiFp(refreshTokenApiConfiguration).refresh(undefined, true)(undefined, refreshTokenApiConfiguration.basePath).then((response) => {
            jwtService.isAlreadyFetchingAccessToken = false
            jwtService.setAccessToken(response.access_token)
            jwtService.setRefreshToken(response.refresh_token)
            jwtService.onAccessTokenFetched(response.access_token)
        }).catch(() => {
            jwtService.onAccessTokenFetched(undefined)
        })
    }
    return refreshAccessTokenResult
}

const createAuthApi = () => {
    const apiConfiguration = new AuthApiConfiguration({
        basePath: process.env.REACT_APP_API_BASE_URL,
        accessToken: () => jwtService.getAccessToken(),
        refreshAccessTokenFunction: refreshAccessToken,
        defaultHeaders: {'Access-Control-Allow-Origin': '*'}
    })
    return new AuthApi(apiConfiguration)
}

const createDataApi = () => {
    const apiConfiguration = new DataApiConfiguration({
        basePath: process.env.REACT_APP_API_BASE_URL,
        accessToken: () => jwtService.getAccessToken(),
        refreshAccessTokenFunction: refreshAccessToken,
        defaultHeaders: {'Access-Control-Allow-Origin': '*'}
    })

    return new DataApi(apiConfiguration)
}

const createAdminApi = () => {
    const apiConfiguration = new AdminApiConfiguration({
        basePath: process.env.REACT_APP_API_BASE_URL,
        accessToken: () => jwtService.getAccessToken(),
        refreshAccessTokenFunction: refreshAccessToken,
        defaultHeaders: {'Access-Control-Allow-Origin': '*'}
    })
    return new AdminApi(apiConfiguration)
}


export const authApi = createAuthApi()
export const dataApi = createDataApi()
export const adminApi = createAdminApi()
