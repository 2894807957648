/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Integria Insurance Backend
 * This is a platform for embedded insurance.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: ezverinskaia@integria-insurance.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as urlUtils from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://api.integria-systems.com/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface BuyerDetails
 */
export interface BuyerDetails {
    /**
     * 
     * @type {string}
     * @memberof BuyerDetails
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDetails
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDetails
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDetails
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDetails
     */
    postCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDetails
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDetails
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerDetails
     */
    shopCustomerId?: string;
}

/**
 * 
 * @export
 * @interface Contract
 */
export interface Contract {
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    insuranceContractId?: string;
    /**
     * Insurance Company Name
     * @type {string}
     * @memberof Contract
     */
    insuranceCompanyName?: string;
    /**
     * Insurance Company Code
     * @type {string}
     * @memberof Contract
     */
    insuranceCompanyCode?: string;
    /**
     * Platform Insurance Name
     * @type {string}
     * @memberof Contract
     */
    insuranceName?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    shopName?: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    shopCode?: string;
    /**
     * Contract Date
     * @type {string}
     * @memberof Contract
     */
    contractDate?: string;
    /**
     * 
     * @type {InsuranceSellingDetails}
     * @memberof Contract
     */
    insuranceSellingDetails?: InsuranceSellingDetails;
}

/**
 * 
 * @export
 * @interface ContractQueryResult
 */
export interface ContractQueryResult {
    /**
     * 
     * @type {Array<Contract>}
     * @memberof ContractQueryResult
     */
    data?: Array<Contract>;
    /**
     * 
     * @type {number}
     * @memberof ContractQueryResult
     */
    total?: number;
}

/**
 * 
 * @export
 * @interface InsuranceSellingDetails
 */
export interface InsuranceSellingDetails {
    /**
     * 
     * @type {string}
     * @memberof InsuranceSellingDetails
     */
    shopOrderId?: string;
    /**
     * Contract Sell Time
     * @type {Date}
     * @memberof InsuranceSellingDetails
     */
    sellTimestamp?: Date;
    /**
     * Name of Product to insure
     * @type {string}
     * @memberof InsuranceSellingDetails
     */
    shopProductName?: string;
    /**
     * Id of Product in Shop (eg. SKU)
     * @type {string}
     * @memberof InsuranceSellingDetails
     */
    shopProductId?: string;
    /**
     * Product Price
     * @type {number}
     * @memberof InsuranceSellingDetails
     */
    shopProductPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceSellingDetails
     */
    currency?: InsuranceSellingDetails_CurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof InsuranceSellingDetails
     */
    shopInsuranceTitle?: string;
    /**
     * Id of Insurance as Product in Shop (eg. SKU)
     * @type {string}
     * @memberof InsuranceSellingDetails
     */
    shopInsuranceId?: string;
    /**
     * Insurance Price
     * @type {number}
     * @memberof InsuranceSellingDetails
     */
    shopInsurancePrice?: number;
    /**
     * Platform Insurance Id
     * @type {string}
     * @memberof InsuranceSellingDetails
     */
    insuranceId?: string;
    /**
     * Number of Products with Insurance Sold
     * @type {number}
     * @memberof InsuranceSellingDetails
     */
    quantity?: number;
    /**
     * 
     * @type {BuyerDetails}
     * @memberof InsuranceSellingDetails
     */
    buyerDetails?: BuyerDetails;
}
/**
 * @export
 * @enum {string}
 */
export enum InsuranceSellingDetails_CurrencyEnum {
    EUR = 'EUR' as any,
    USD = 'USD' as any
}


/**
 * DataApi - fetch parameter creator
 * @export
 */
export const DataApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Saves information about product, insurance, customer
         * @summary Creates a contract
         * @param {InsuranceSellingDetails} insuranceSellingDetails Complete Information about Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContract(insuranceSellingDetails: InsuranceSellingDetails, options: any = {}): FetchArgs {
            // verify required parameter 'insuranceSellingDetails' is not null or undefined
            if (insuranceSellingDetails === null || insuranceSellingDetails === undefined) {
                throw new RequiredError('insuranceSellingDetails','Required parameter insuranceSellingDetails was null or undefined when calling addContract.');
            }
            const localVarPath = `/contracts`;
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);
            const needsSerialization = ("InsuranceSellingDetails" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(insuranceSellingDetails || {}) : (insuranceSellingDetails || "");

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get contract by contract id
         * @summary Get contract by contract id
         * @param {string} contractId Contract Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById(contractId: string, options: any = {}): FetchArgs {
            // verify required parameter 'contractId' is not null or undefined
            if (contractId === null || contractId === undefined) {
                throw new RequiredError('contractId','Required parameter contractId was null or undefined when calling getContractById.');
            }
            const localVarPath = `/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of contracts
         * @summary Returns a list of contracts allowed to see by user logged in
         * @param {number} [page] Page Number
         * @param {number} [size] Page Size
         * @param {string} [sort] Sort order
         * @param {string} [sortColumn] Sort column
         * @param {string} [searchQuery] Search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractList(page?: number, size?: number, sort?: string, sortColumn?: string, searchQuery?: string, options: any = {}): FetchArgs {
            const localVarPath = `/contracts`;
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['sortColumn'] = sortColumn;
            }

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataApi - functional programming interface
 * @export
 */
export const DataApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Saves information about product, insurance, customer
         * @summary Creates a contract
         * @param {InsuranceSellingDetails} insuranceSellingDetails Complete Information about Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContract(insuranceSellingDetails: InsuranceSellingDetails, options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DataApiFetchParamCreator(configuration).addContract(insuranceSellingDetails, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.addContract(insuranceSellingDetails, options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get contract by contract id
         * @summary Get contract by contract id
         * @param {string} contractId Contract Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById(contractId: string, options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<Contract> {
            const localVarFetchArgs = DataApiFetchParamCreator(configuration).getContractById(contractId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return new Promise((resolve, reject) =>
                            response.text()
                                .then((text) => resolve(text ? JSON.parse(text) : null))
                                .catch((reason) => reject(reason)));
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.getContractById(contractId, options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns a list of contracts
         * @summary Returns a list of contracts allowed to see by user logged in
         * @param {number} [page] Page Number
         * @param {number} [size] Page Size
         * @param {string} [sort] Sort order
         * @param {string} [sortColumn] Sort column
         * @param {string} [searchQuery] Search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractList(page?: number, size?: number, sort?: string, sortColumn?: string, searchQuery?: string, options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<ContractQueryResult> {
            const localVarFetchArgs = DataApiFetchParamCreator(configuration).getContractList(page, size, sort, sortColumn, searchQuery, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return new Promise((resolve, reject) =>
                            response.text()
                                .then((text) => resolve(text ? JSON.parse(text) : null))
                                .catch((reason) => reject(reason)));
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.getContractList(page, size, sort, sortColumn, searchQuery, options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DataApi - factory interface
 * @export
 */
export const DataApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Saves information about product, insurance, customer
         * @summary Creates a contract
         * @param {InsuranceSellingDetails} insuranceSellingDetails Complete Information about Contract
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContract(insuranceSellingDetails: InsuranceSellingDetails, options?: any) {
            return DataApiFp(configuration).addContract(insuranceSellingDetails, options)(fetch, basePath);
        },
        /**
         * Get contract by contract id
         * @summary Get contract by contract id
         * @param {string} contractId Contract Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractById(contractId: string, options?: any) {
            return DataApiFp(configuration).getContractById(contractId, options)(fetch, basePath);
        },
        /**
         * Returns a list of contracts
         * @summary Returns a list of contracts allowed to see by user logged in
         * @param {number} [page] Page Number
         * @param {number} [size] Page Size
         * @param {string} [sort] Sort order
         * @param {string} [sortColumn] Sort column
         * @param {string} [searchQuery] Search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractList(page?: number, size?: number, sort?: string, sortColumn?: string, searchQuery?: string, options?: any) {
            return DataApiFp(configuration).getContractList(page, size, sort, sortColumn, searchQuery, options)(fetch, basePath);
        },
    };
};

/**
 * DataApi - object-oriented interface
 * @export
 * @class DataApi
 * @extends {BaseAPI}
 */
export class DataApi extends BaseAPI {
    /**
     * Saves information about product, insurance, customer
     * @summary Creates a contract
     * @param {InsuranceSellingDetails} insuranceSellingDetails Complete Information about Contract
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public addContract(insuranceSellingDetails: InsuranceSellingDetails, options?: any) {
        return DataApiFp(this.configuration).addContract(insuranceSellingDetails, options)(this.fetch, this.basePath);
    }

    /**
     * Get contract by contract id
     * @summary Get contract by contract id
     * @param {string} contractId Contract Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getContractById(contractId: string, options?: any) {
        return DataApiFp(this.configuration).getContractById(contractId, options)(this.fetch, this.basePath);
    }

    /**
     * Returns a list of contracts
     * @summary Returns a list of contracts allowed to see by user logged in
     * @param {number} [page] Page Number
     * @param {number} [size] Page Size
     * @param {string} [sort] Sort order
     * @param {string} [sortColumn] Sort column
     * @param {string} [searchQuery] Search query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataApi
     */
    public getContractList(page?: number, size?: number, sort?: string, sortColumn?: string, searchQuery?: string, options?: any) {
        return DataApiFp(this.configuration).getContractList(page, size, sort, sortColumn, searchQuery, options)(this.fetch, this.basePath);
    }

}

