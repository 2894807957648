// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {adminApi} from "@src/api"

export const getData = createAsyncThunk('companies/getData', async params => {
  // eslint-disable-next-line no-unused-vars
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = params
  const response = await adminApi.getCompanyList(page, perPage, sort, sortColumn, q)
  return {
    params,
    data: response.data,
    total: response.total
  }
})

/*export const addCompany = createAsyncThunk('companies/addCompany', async (params) => {
  // eslint-disable-next-line no-unused-vars
  const { companyId, companyName } = params
  const response = await adminApi.createUser({
    companyId
  })
  return {
    newUserCompanyName: companyName,
    newUserId: response.userId,
    newUserRegistrationCode: response.registrationCode
  }
})*/

export const addUser = createAsyncThunk('companies/addUser', async (params) => {
  // eslint-disable-next-line no-unused-vars
  const { companyId, companyName } = params
  const response = await adminApi.createUser({
    companyId
  })
  return {
    newUserCompanyName: companyName,
    newUserId: response.userId,
    newUserRegistrationCode: response.registrationCode
  }
})

export const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    data: [],
    total: 0,
    params: {},
    newUserId: undefined,
    newUserRegistrationCode: undefined
  },
  reducers: {
    eraseNewUserData: (state) => {
      state.newUserCompanyName = undefined
      state.newUserId = undefined
      state.newUserRegistrationCode = undefined
    }
  },
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.total
      state.params = action.payload.params
    })
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.newUserCompanyName = action.payload.newUserCompanyName
      state.newUserId = action.payload.newUserId
      state.newUserRegistrationCode = action.payload.newUserRegistrationCode
    })
  }
})

export const { eraseNewUserData } = companiesSlice.actions

export default companiesSlice.reducer
