export default class JwtService {

  LOCAL_STORAGE_ACCESS_TOKEN_KEY = "access_token"
  LOCAL_STORAGE_REFRESH_TOKEN_KEY = "refresh_token"

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor() {

  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getAccessToken() {
    return localStorage.getItem(this.LOCAL_STORAGE_ACCESS_TOKEN_KEY)
  }

  getRefreshToken() {
    return localStorage.getItem(this.LOCAL_STORAGE_REFRESH_TOKEN_KEY)
  }

  setAccessToken(value) {
    localStorage.setItem(this.LOCAL_STORAGE_ACCESS_TOKEN_KEY, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.LOCAL_STORAGE_REFRESH_TOKEN_KEY, value)
  }
}
