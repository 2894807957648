/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Administration API
 * Administration API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: ezverinskaia@integria-insurance.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as urlUtils from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://api.integria-systems.com/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * Company Name
     * @type {number}
     * @memberof Company
     */
    id?: number;
    /**
     * Company Name
     * @type {string}
     * @memberof Company
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface CompanyQueryResult
 */
export interface CompanyQueryResult {
    /**
     * 
     * @type {Array<Company>}
     * @memberof CompanyQueryResult
     */
    data?: Array<Company>;
    /**
     * 
     * @type {number}
     * @memberof CompanyQueryResult
     */
    total?: number;
}

/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateUserRequest
     */
    companyId?: number;
}

/**
 * 
 * @export
 * @interface CreateUserResponse
 */
export interface CreateUserResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateUserResponse
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponse
     */
    registrationCode?: string;
}


/**
 * AdminApi - fetch parameter creator
 * @export
 */
export const AdminApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new user
         * @summary Create new user
         * @param {CreateUserRequest} createUserRequest Create user request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserRequest: CreateUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'createUserRequest' is not null or undefined
            if (createUserRequest === null || createUserRequest === undefined) {
                throw new RequiredError('createUserRequest','Required parameter createUserRequest was null or undefined when calling createUser.');
            }
            const localVarPath = `/admin/createUser`;
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);
            const needsSerialization = ("CreateUserRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(createUserRequest || {}) : (createUserRequest || "");

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of all companies
         * @summary Returns a list of all companies
         * @param {number} [page] Page Number
         * @param {number} [size] Page Size
         * @param {string} [sort] Sort order
         * @param {string} [sortColumn] Sort column
         * @param {string} [searchQuery] Search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyList(page?: number, size?: number, sort?: string, sortColumn?: string, searchQuery?: string, options: any = {}): FetchArgs {
            const localVarPath = `/admin/companies`;
            const localVarUrlObj = urlUtils.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['sortColumn'] = sortColumn;
            }

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            // @ts-ignore
            delete localVarUrlObj.search;
            const defaultHeaders = configuration && configuration.defaultHeaders ? configuration.defaultHeaders : {};
            localVarRequestOptions.headers = Object.assign({}, defaultHeaders, localVarHeaderParameter, options.headers);

            return {
                url: urlUtils.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Create new user
         * @summary Create new user
         * @param {CreateUserRequest} createUserRequest Create user request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserRequest: CreateUserRequest, options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<CreateUserResponse> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).createUser(createUserRequest, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return new Promise((resolve, reject) =>
                            response.text()
                                .then((text) => resolve(text ? JSON.parse(text) : null))
                                .catch((reason) => reject(reason)));
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.createUser(createUserRequest, options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns a list of all companies
         * @summary Returns a list of all companies
         * @param {number} [page] Page Number
         * @param {number} [size] Page Size
         * @param {string} [sort] Sort order
         * @param {string} [sortColumn] Sort column
         * @param {string} [searchQuery] Search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyList(page?: number, size?: number, sort?: string, sortColumn?: string, searchQuery?: string, options?: any, noRetry?: boolean): (fetch?: FetchAPI, basePath?: string) => Promise<CompanyQueryResult> {
            const localVarFetchArgs = AdminApiFetchParamCreator(configuration).getCompanyList(page, size, sort, sortColumn, searchQuery, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return new Promise((resolve, reject) =>
                            response.text()
                                .then((text) => resolve(text ? JSON.parse(text) : null))
                                .catch((reason) => reject(reason)));
                    } else if (response.status == 401 && !noRetry && configuration?.refreshAccessTokenFunction) {
                        return new Promise((resolve, reject) => {
                            configuration!.refreshAccessTokenFunction!().then((accessToken) => {
                                if (accessToken) {
                                    resolve(this.getCompanyList(page, size, sort, sortColumn, searchQuery, options, true)(fetch, basePath));
                                } else {
                                    reject(response);
                                }
                            });
                        });
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Create new user
         * @summary Create new user
         * @param {CreateUserRequest} createUserRequest Create user request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(createUserRequest: CreateUserRequest, options?: any) {
            return AdminApiFp(configuration).createUser(createUserRequest, options)(fetch, basePath);
        },
        /**
         * Returns a list of all companies
         * @summary Returns a list of all companies
         * @param {number} [page] Page Number
         * @param {number} [size] Page Size
         * @param {string} [sort] Sort order
         * @param {string} [sortColumn] Sort column
         * @param {string} [searchQuery] Search query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyList(page?: number, size?: number, sort?: string, sortColumn?: string, searchQuery?: string, options?: any) {
            return AdminApiFp(configuration).getCompanyList(page, size, sort, sortColumn, searchQuery, options)(fetch, basePath);
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Create new user
     * @summary Create new user
     * @param {CreateUserRequest} createUserRequest Create user request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public createUser(createUserRequest: CreateUserRequest, options?: any) {
        return AdminApiFp(this.configuration).createUser(createUserRequest, options)(this.fetch, this.basePath);
    }

    /**
     * Returns a list of all companies
     * @summary Returns a list of all companies
     * @param {number} [page] Page Number
     * @param {number} [size] Page Size
     * @param {string} [sort] Sort order
     * @param {string} [sortColumn] Sort column
     * @param {string} [searchQuery] Search query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getCompanyList(page?: number, size?: number, sort?: string, sortColumn?: string, searchQuery?: string, options?: any) {
        return AdminApiFp(this.configuration).getCompanyList(page, size, sort, sortColumn, searchQuery, options)(this.fetch, this.basePath);
    }

}

