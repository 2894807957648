// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {dataApi} from "@src/api"

export const getData = createAsyncThunk('contracts/getData', async params => {
  // eslint-disable-next-line no-unused-vars
  const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = params
  const response = await dataApi.getContractList(page, perPage, sort, sortColumn, q)
  return {
    params,
    data: response.data,
    total: response.total
  }
})

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState: {
    data: [],
    total: 0,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.total
      state.params = action.payload.params
    })
  }
})

export default contractsSlice.reducer
